import React from 'react';
import { Layout } from '../components/shared/Layout';

const AanmeldenPage = (): JSX.Element => {
  return (
    <Layout>
      <main className="">
        <section className="bg-white py-8">
          <div className="container mx-auto max-w-xl">
            <h1>Aanmelden</h1>
            <p>Coming soon</p>
          </div>
        </section>
      </main>
    </Layout>
  );
};

export default AanmeldenPage;
